import lozad from 'lozad'
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
import Isotope from 'isotope-layout';
import IsotopePackery from 'isotope-packery';
import imagesLoaded from 'imagesloaded';

Swiper.use([Navigation, Pagination, Scrollbar]);

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();

    const swiper = new Swiper('.project-swiper', {
        scrollbar: {
            el: '.js-scrollbar',
            draggable: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: number => '0' + number,
            formatFractionTotal: number => '0' + number,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    const singleSwiper = new Swiper('.single-swiper', {
        scrollbar: {
            el: '.js-scrollbar',
            draggable: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: number => '0' + number,
            formatFractionTotal: number => '0' + number,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // logo swiper
    const logoSwiper = new Swiper('.logo-swiper', {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1024px)" );
    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    // Open Menu
    const navTrigger = document.querySelector('.js-open-nav');
    const projectWrapper = document.getElementById('app');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        projectWrapper.classList.toggle('js-is-open');

        if (this.classList.contains('is-active')) {
            drawer.open();
        } else {
            drawer.close();
        }

    }, false);

    // OVERLAY FUNCTIONALITY
    const overlayTriggers = document.querySelectorAll('.has-bio'),
    overlayTeam = document.getElementById('overlay-team'),
    close = document.getElementById('js-close-modal'),
    app = document.getElementById('app'),
    modal = document.querySelector('.js-modal');

    if(overlayTriggers.length){
        for (var i = 0; i < overlayTriggers.length; i++) {
            overlayTriggers[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                overlayTeam.classList.add('show');

                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                app.style.position = 'fixed';
                app.style.top = `-${scrollY}`;
                document.querySelector('footer').style = 'display: none'

                overlayTeam.querySelector('#overlay-description').innerHTML = this.querySelector('#bio-description').innerHTML;
                overlayTeam.querySelector('#overlay-position').innerHTML = this.querySelector('#bio-position').innerHTML;
                overlayTeam.querySelector('#overlay-name').innerHTML = this.querySelector('#bio-name').innerHTML;
            });
        }

        close.addEventListener('click', function(){
            overlayTeam.classList.remove("show");
            const scrollY = app.style.top;
            app.style.position = '';
            app.style.top = '';
            document.querySelector('footer').style = 'display: block'

            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Closes the modal when the document is clicked
        overlayTeam.addEventListener('click', () => {
            overlayTeam.classList.remove('show');
            const scrollY = app.style.top;
            app.style.position = '';
            app.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        modal.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    // init Isotope

    var elem = document.querySelector('.grid');
    if(elem) {
        var iso = new Isotope( elem, {
            // options
            itemSelector: '.grid__item',
            layoutMode: 'packery',
            packery: {
                //gutter: 10
            }
        });
    }

    if(document.querySelector('.grid')) {
        // load images before initiating isotope
        imagesLoaded( document.querySelector('.grid'), function( instance ) {
            console.log('all images are loaded');

            // init Isotope

            var elem = document.querySelector('.grid');
            var iso = new Isotope( elem, {
                // options
                itemSelector: '.grid__item',
                layoutMode: 'packery',
                packery: {
                    //gutter: 10
                }
            });
        });
    }

    // ACCORDIONS
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {

        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})